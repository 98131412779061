import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import operation from "../../images/makers/operation.png"
import sanitas from "../../images/makers/sanitas.png"
import ursus from "../../images/makers/ursus.png"
import santo from "../../images/makers/santo.png"

import david11 from "../../images/makers/david11.jpg"
import david12 from "../../images/makers/david12.jpg"
import david13 from "../../images/makers/david13.jpg"
import david14 from "../../images/makers/david14.jpg"
import telcel from "../../images/makers/telcel.jpg"
import david1 from "../../images/makers/david1.jpg"
import david2 from "../../images/makers/david2.jpg"
import david3 from "../../images/makers/david3.jpg"
import david4 from "../../images/makers/david4.jpg"
import david5 from "../../images/makers/david5.jpg"
import david6 from "../../images/makers/david6.jpg"
import david7 from "../../images/makers/david7.jpg"
import david8 from "../../images/makers/david8.jpg"
import david9 from "../../images/makers/david9.jpg"
import david10 from "../../images/makers/david10.jpg"
import david_timisoriana from "../../images/makers/david_timisoriana.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">David<br/>Alcalde</h2>

            <div className="maker_wrapper">
              
              <div className="maker_container"><img key="720078630" src={operation} className="maker_image" video="https://vimeo.com/720078630" onClick={this.imageClick} alt="720078630"/></div>
              <div className="maker_container"><img key="720078702" src={sanitas} className="maker_image" video="https://vimeo.com/720078702" onClick={this.imageClick} alt="720078702"/></div>
              <div className="maker_container"><img key="720078800" src={ursus} className="maker_image" video="https://vimeo.com/720078800" onClick={this.imageClick} alt="720078800"/></div>
              <div className="maker_container"><img key="720078332" src={santo} className="maker_image" video="https://vimeo.com/720078332" onClick={this.imageClick} alt="720078332"/></div>
              
              <div className="maker_container"><img key="362636505" src={david11} className="maker_image" video="https://vimeo.com/362636505" onClick={this.imageClick} alt="362636505"/></div>
              <div className="maker_container"><img key="362637692" src={david12} className="maker_image" video="https://vimeo.com/362637692" onClick={this.imageClick} alt="362637692"/></div>
              <div className="maker_container"><img key="362639050" src={david13} className="maker_image" video="https://vimeo.com/362639050" onClick={this.imageClick} alt="362639050"/></div>
              <div className="maker_container"><img key="362639977" src={david14} className="maker_image" video="https://vimeo.com/362639977" onClick={this.imageClick} alt="362639977"/></div>
              <div className="maker_container"><img key="265304615" src={telcel} className="maker_image" video="https://vimeo.com/265304615" onClick={this.imageClick} alt="265304615"/></div>
              <div className="maker_container"><img key="214746306" src={david1} className="maker_image" video="https://vimeo.com/214746306" onClick={this.imageClick} alt="214746306"/></div>
              <div className="maker_container"><img key="163679914" src={david2} className="maker_image" video="https://vimeo.com/163679914" onClick={this.imageClick} alt="163679914"/></div>
              <div className="maker_container"><img key="128910148" src={david3} className="maker_image" video="https://vimeo.com/128910148" onClick={this.imageClick} alt="128910148"/></div>
              <div className="maker_container"><img key="120410445" src={david4} className="maker_image" video="https://vimeo.com/120410445" onClick={this.imageClick} alt="120410445"/></div>
              <div className="maker_container"><img key="120412742" src={david5} className="maker_image" video="https://vimeo.com/120412742" onClick={this.imageClick} alt="120412742"/></div>
              <div className="maker_container"><img key="122025379" src={david6} className="maker_image" video="https://vimeo.com/122025379" onClick={this.imageClick} alt="122025379"/></div>
              <div className="maker_container"><img key="120436254" src={david7} className="maker_image" video="https://vimeo.com/120436254" onClick={this.imageClick} alt="120436254"/></div>
              <div className="maker_container"><img key="120437519" src={david8} className="maker_image" video="https://vimeo.com/120437519" onClick={this.imageClick} alt="120437519"/></div>
              <div className="maker_container"><img key="168195278" src={david9} className="maker_image" video="https://vimeo.com/168195278" onClick={this.imageClick} alt="168195278"/></div>
              <div className="maker_container"><img key="120437520" src={david10} className="maker_image" video="https://vimeo.com/120437520" onClick={this.imageClick} alt="120437520"/></div>
              <div className="maker_container"><img key="365406575" src={david_timisoriana} className="maker_image" video="https://vimeo.com/365406575" onClick={this.imageClick} alt="365406575"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;