import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import vivabonito0 from "../../images/contents/vivabonito0.jpg"
import vivabonito1 from "../../images/contents/vivabonito1.jpg"
import vivabonito2 from "../../images/contents/vivabonito2.jpg"
import vivabonito3 from "../../images/contents/vivabonito3.jpg"
import vivabonito4 from "../../images/contents/vivabonito4.jpg"
import vivabonito5 from "../../images/contents/vivabonito5.jpg"
import vivabonito6 from "../../images/contents/vivabonito6.jpg"
import vivabonito7 from "../../images/contents/vivabonito7.jpg"
import vivabonito8 from "../../images/contents/vivabonito8.jpg"
import vivabonito9 from "../../images/contents/vivabonito9.jpg"
import vivabonito10 from "../../images/contents/vivabonito10.jpg"
import vivabonito11 from "../../images/contents/vivabonito11.jpg"
import vivabonito12 from "../../images/contents/vivabonito12.jpg"
import vivabonito13 from "../../images/contents/vivabonito13.jpg"
import vivabonito14 from "../../images/contents/vivabonito14.jpg"
import vivabonito15 from "../../images/contents/vivabonito15.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">VIVA BONITO<br/>by LIVERPOOL</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="361139899" src={vivabonito0} className="maker_image" video="https://vimeo.com/361139899" onClick={this.imageClick} alt="361139899"/></div>
              <div className="maker_container"><img key="361110681" src={vivabonito1} className="maker_image" video="https://vimeo.com/361110681" onClick={this.imageClick} alt="361110681"/></div>
              <div className="maker_container"><img key="361111026" src={vivabonito2} className="maker_image" video="https://vimeo.com/361111026" onClick={this.imageClick} alt="361111026"/></div>
              <div className="maker_container"><img key="361111417" src={vivabonito3} className="maker_image" video="https://vimeo.com/361111417" onClick={this.imageClick} alt="361111417"/></div>
              <div className="maker_container"><img key="361111828" src={vivabonito4} className="maker_image" video="https://vimeo.com/361111828" onClick={this.imageClick} alt="361111828"/></div>
              <div className="maker_container"><img key="361113802" src={vivabonito5} className="maker_image" video="https://vimeo.com/361113802" onClick={this.imageClick} alt="361113802"/></div>
              <div className="maker_container"><img key="361114066" src={vivabonito6} className="maker_image" video="https://vimeo.com/361114066" onClick={this.imageClick} alt="361114066"/></div>
              <div className="maker_container"><img key="361114553" src={vivabonito7} className="maker_image" video="https://vimeo.com/361114553" onClick={this.imageClick} alt="361114553"/></div>
              <div className="maker_container"><img key="361115051" src={vivabonito8} className="maker_image" video="https://vimeo.com/361115051" onClick={this.imageClick} alt="361115051"/></div>
              <div className="maker_container"><img key="361115622" src={vivabonito9} className="maker_image" video="https://vimeo.com/361115622" onClick={this.imageClick} alt="361115622"/></div>
              <div className="maker_container"><img key="361115953" src={vivabonito10} className="maker_image" video="https://vimeo.com/361115953" onClick={this.imageClick} alt="361115953"/></div>
              <div className="maker_container"><img key="361116487" src={vivabonito11} className="maker_image" video="https://vimeo.com/361116487" onClick={this.imageClick} alt="361116487"/></div>
              <div className="maker_container"><img key="361117052" src={vivabonito12} className="maker_image" video="https://vimeo.com/361117052" onClick={this.imageClick} alt="361117052"/></div>
              <div className="maker_container"><img key="361117181" src={vivabonito13} className="maker_image" video="https://vimeo.com/361117181" onClick={this.imageClick} alt="361117181"/></div>
              <div className="maker_container"><img key="361117255" src={vivabonito14} className="maker_image" video="https://vimeo.com/361117255" onClick={this.imageClick} alt="361117255"/></div>
              <div className="maker_container"><img key="361117591" src={vivabonito15} className="maker_image" video="https://vimeo.com/361117591" onClick={this.imageClick} alt="361117591"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;