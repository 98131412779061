import React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';

import Card from '../components/Card';

import Agustin from '../images/filmmakers/Agustin.jpeg';
import Alex from '../images/filmmakers/Alex.JPG';
import David from '../images/filmmakers/David.jpeg';
import Homies from '../images/filmmakers/Homies.JPG';
import Krist from '../images/filmmakers/Krist.jpg';
import Leo from '../images/filmmakers/Leo.jpg';
import Mario from '../images/filmmakers/Mario.JPG';
import Martin from '../images/filmmakers/Martin.JPG';
import Rafa from '../images/filmmakers/Rafa.jpeg';
import Roby from '../images/filmmakers/Roby.JPG';
import Toledo from '../images/filmmakers/Toledo.jpeg';

class Filmmakers extends React.Component {

  render () {

    return (

      <React.Fragment>

        <div className="main filmmakers">

          <Header />

          <div className="content">

            <h2 className="title">The<br/>Filmmakers</h2>
            <p className="subtitle">by alphabetical order</p>

            <p className="paragraph">
              Over the years, we have assembled a high-end team of advertising videos, TV series and cinema directors who fully understand the industry and the objectives of our clients.  Storytellers that encapsulate the most pleasant emotions in a short space of time, thus generating important ROI for our clients.
            </p>

            <Card 
              image={Agustin}
              title="Agustín Calderón" 
              paragraph="Born in Mexico City, from childhood, he was involved in cinematographic environment, going through practically all the career ladders with success.  He studied cinematography and photography at the Centro de Capacitación Cinematográfica where he received Summa Cum Laude in 1999 with the short film 'Inaudito'.  As photographer, he has hundreds of commercials, several short films and documentaries.  His work has received many national and international awards, including Cannes Lions and Clio Awards.  If we talk about Artistic Food at the screen, nobody like Agus Calderón. With his magical view of 1200 frames per second, he finds the divinity in a small sesame seed or in hectares of crops, creating with his unique techniques, exquisite waves of colors, fragrances, and flavors, awakening the appetite of any viewer." 
              reel="agustin"
            />

            <Card 
              image={Alex}
              title="Alex Z. Reynaud" 
              paragraph="Mexican filmmaker and photographer. His restlessness has led him to shoot around the world.  His digital and avant-garde pieces have led him to participate at campaigns for important brands.  A cinematographer who understands the needs of a market that is constantly changing, especially when talking about corporate videos and social media where brands advertise to their targets." 
              reel="alex"
              odd="true"
            />
            <Card 
              image={David}
              title="David Alcalde" 
              paragraph="Spanish director with a splendid career.  Got an Oscar for his special effects at El Laberinto del Fauno, directed by Guillermo del Toro.  Each of his audiovisual productions contains magic. He has won many awards and worked for various countries such as Germany, Japan, Italy, India, Mexico, Chile, Argentina, South Africa, Scotland, Thailand, Russia, United States, Dubai, England, Spain, Qatar, etc.  Throughout his career, he has directed many celebrities such as Alejandro Sanz, Pablo López, Christina Rosenvinge, Carlos Santana, Miguel Bosé, La Mala Rodríguez, M-Clan, Rosendo, Luz Casal, Javier Bardem, Farhan Akhtar, Marc Márquez, Sebastián Vettel, Kimi Räikkönen, Pau y Marc Gasol, James Rodriguez, Cesc Fábregas, Ronaldinho, Marilyn Manson, Milla Jovovich, Diego Paris, Raúl Jiménez, Natalia Rodríguez, Alex Gonzales among many others.   Working with David, will make your campaign reach very high levels." 
              reel="david"
            />

            <Card 
              image={Krist}
              title="Krist Alexander" 
              paragraph="Director, screenwriter, and visual artist with a fresh, daring, and innovative look.  After working in the film industry as AD, in both Mexico and Europe, he decided to make his way as director.  Krist stands out for his versatility and strict care for aesthetics, with a language full of sensitivity, a powerful visual style and a poetic narrative sense, these characteristics have led him to carry out different audiovisual projects, highlighting fiction, advertising, and video clips." 
              reel="krist"
              odd="true"
            />

            <Card 
              image={Leo}
              title="Leo Ganora" 
              paragraph="Leo is an advertising film director who makes fantastic matches between the digital world (2D, 3D, VFX, MG, etc.) and real life. He also directs actors creating spectacular narratives. He uses colorimetry in a very aesthetic way creating artistic images in each of his pieces. He is winner of Clio, FIAP, Cannes, Havana Festival awards, among others." 
              reel="leo"
            />

            <Card 
              image={Homies}
              title="Los Homies" 
              paragraph="Los Homies is a duo of filmmakers, detailed, lovers of symmetry, color, and textures, always in search of the daring and crazy creative ideas.  Doubly focused, Los Homies, have a career in the audiovisual world, working on documentary projects, short films, video clips and commercials, where some of their works have won awards such as Cannes, Festival del Sol, Círculo Creativo and Effies." 
              reel="homies"
              odd="true"
            />

            <Card 
              image={Mario}
              title="Mario Zavala" 
              paragraph="Born in Mexico City. He studied Audiovisual Communication at the Universidad del Claustro de Sor Juana, developing a taste for storytelling: writing, directing and sometimes acting to appropriate anecdotes and translate them into images.  He made his way in advertising creating treatments for top international directors, which gave him the opportunity to go from AD to directing second unities, achieving great experience on the set.  Now he is a well-known director.  What he enjoys the most is creating emotional bonds with the viewer through the stories that we tell hand by hand with our clients." 
              reel="mario_zavala"
            />

            <Card 
              image={Martin}
              title="Martín Pimentel" 
              paragraph="Born in Buenos Aires, Argentina, and lives in CDMX. He has held various positions in advertising, television, and film production companies.  At filmmaking he debuted with several music videos and non-traditional digital content. His experience is printed at his artistic vision to the world of advertising and digital content, creating commercials that stand out for his unique visual ambition.  In its contents you can appreciate the contribution of freshness, new narrative languages, and great versatility to move in this changing world.  Immersed in the digital world, he runs campaigns for top-tier brands for the entire Latin American market." 
              reel="martin"
              odd="true"
            />

            <Card 
              image={Rafa}
              title="Rafa Lara" 
              paragraph="Mexican director, showrunner, and screenwriter. He has made all kinds of audiovisual production such as documentaries, TV series, commercials, short films and renowned movies such as La Milagrosa (2008), El Quinto Mandamiento (2010), Labios Rojos (2011), Cinco de Mayo: La Batalla (2013), Aquí no pasa nada (2014), El tamaño sí importa (2016), Jesus of Nazareth (2017), Señora Acero (2018), the Amazon Prime Video TV Series: De Brutas Nada (2019-2022), and the ones coming soon.  A great knowledge in narrative, character creation and direction of actors is his thing." 
              reel="rafa"
            />

            <Card 
              image={Roby}
              title="Roby Chiralt" 
              paragraph="Born in Buenos Aires, Argentina, Roby began working in advertising production in 1999 and from then on, he has not stopped receiving national and international awards such as Golden Pencil, Eagle Award Mexico, Fiap, Sol de Bronce, Aspid Oro, among others.  He has been working for Argentina, Mexico, Chile, USA and Italy.  More than two decades of experience are reflected at his work with a realistic style and sensitive look, achieving a unique and aesthetic piece in each project." 
              reel="roby"
              odd="true"
            />

            <Card 
              image={Toledo}
              title="Toledo & Zavala" 
              paragraph="Spain and Mexico come together in an alliance of creativity and skill. Spanish director Alejandro Toledo and Mexican director Mario Zavala join their talents to create a wonderful tandem achieving state of the art projects with international standards.  Two points of view, each with its own history, different worlds that come together and join in an exchange of ideas in syncrety.   “This duo is born from difference and complement, always guiding us with the same goal; deliver all our creativity, vision, experience, and all the love in each project we carry out. Using duality as an accomplice, we want to make a difference” T&Z" 
              reel="toledo_zavala"
            />


          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;