import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
 
export class MapContainer extends React.Component {

  render() {

		const mapStyles = {
		  width: '100%',
		  height: '100%',
		};

    return (
      <Map
          google={this.props.google}
          initialCenter={{
            lat: 19.42774921844754,
            lng: -99.19659037212728
          }}
          style={mapStyles}
          zoom={16}>
        <Marker title="TheRep MX" position={{ lat: 19.42774921844754, lng: -99.19659037212728}} />
      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ("AIzaSyDsPachNGew-lWMyphKJLxlvjXaYYZaJcQ")
})(MapContainer)