import React, { Component } from 'react';

class Footer extends Component {

	render () {

		return (

			<footer className="footer">

				<div className="footer_content">

					{/*<p className="footer_info">Av. Ejército Nacional 769, Ampliación Granada, Miguel Hidalgo.<br/>ZIP 11520, CDMX, México. Corporativo Miyana Piso 2.</p>*/}
					<p className="footer_info">Alejandro Dumas 16, Polanco, Miguel Hidalgo<br/>ZIP Code 11550, CDMX, México</p>
					<p className="footer_info">produccion@therep.mx</p>

					<div className="footer_social_container">
						<div className="footer_social_wrapper">
							<a href="https://www.facebook.com/therepmx" target="_blank" rel="noopener noreferrer" className="footer_social"><i className="fab fa-facebook-f"></i></a>
						</div>
						<div className="footer_social_wrapper">
							<a href="https://www.instagram.com/laproducer_therepmx/" target="_blank" rel="noopener noreferrer" className="footer_social"><i class="fab fa-solid fa-instagram"></i></a>
						</div>
					</div>

				</div>

			</footer>

		);
	}
}

export default Footer;
