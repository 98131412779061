import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import milkaut from "../../images/makers/milkaut.png"
import saladitas from "../../images/makers/saladitas.png"
import depend from "../../images/makers/depend.png"
import colgate from "../../images/makers/colgate.png"
import colgate_02 from "../../images/makers/colgate_02.png"
import tigres from "../../images/makers/tigres.png"

import martin_reel from "../../images/makers/martin_reel.jpg"
import martin_jump2 from "../../images/makers/martin_jump2.jpg"
import martin_jump from "../../images/makers/martin_jump.jpg"
import martin_adidas from "../../images/makers/martin_adidas.jpg"
import martin_seriepatia from "../../images/makers/martin_seriepatia.jpg"
import martin_beetle from "../../images/makers/martin_beetle.jpg"
import martin_mb from "../../images/makers/martin_mb.jpg"
import martin_mercadopago from "../../images/makers/martin_mercadopago.jpg"
import martin_quaker from "../../images/makers/martin_quaker.jpg"
import martin_carola from "../../images/makers/martin_carola.jpg"
import martin_cheetos from "../../images/makers/martin_cheetos.jpg"
import martin_speedy from "../../images/makers/martin_speedy.jpg"
import martin_edenor from "../../images/makers/martin_edenor.jpg"
import martin_metrovias from "../../images/makers/martin_metrovias.jpg"

import martin_rafael from "../../images/makers/martin_rafael.png"
import martin_fifa from "../../images/makers/martin_fifa.png"
import martin_santander from "../../images/makers/martin_santander.png"
import martin_botika from "../../images/makers/martin_botika.png"

import martin_whiskas from "../../images/makers/martin_whiskas.jpg"

import martin_nair from "../../images/makers/martin_nair.png"
import martin_depend from "../../images/makers/martin_depend.png"
import martin_adler from "../../images/makers/martin_adler.png"
import martin_milkaut from "../../images/makers/martin_milkaut.png"
import martin_vaquita from "../../images/makers/martin_vaquita.png"
import martin_luuna from "../../images/makers/martin_luuna.png"

import martin_qiub from "../../images/makers/martin_qiub.png"
import martin_anz from "../../images/makers/martin_anz.png"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">Martín<br/>Pimentel</h2>

            <div className="maker_wrapper">

              <div className="maker_container"><img key="713838754" src={milkaut} className="maker_image" video="https://vimeo.com/713838754" onClick={this.imageClick} alt="713838754"/></div>
              <div className="maker_container"><img key="713838349" src={saladitas} className="maker_image" video="https://vimeo.com/713838349" onClick={this.imageClick} alt="713838349"/></div>
              <div className="maker_container"><img key="713838193" src={depend} className="maker_image" video="https://vimeo.com/713838193" onClick={this.imageClick} alt="713838193"/></div>
              <div className="maker_container"><img key="713838080" src={colgate} className="maker_image" video="https://vimeo.com/713838080" onClick={this.imageClick} alt="713838080"/></div>
              <div className="maker_container"><img key="713837848" src={colgate_02} className="maker_image" video="https://vimeo.com/713837848" onClick={this.imageClick} alt="713837848"/></div>
              <div className="maker_container"><img key="713837277" src={tigres} className="maker_image" video="https://vimeo.com/713837277" onClick={this.imageClick} alt="713837277"/></div>
              
              <div className="maker_container"><img key="639308266" src={martin_qiub} className="maker_image" video="https://vimeo.com/639308266" onClick={this.imageClick} alt="639308266"/></div>
              <div className="maker_container"><img key="639305553" src={martin_anz} className="maker_image" video="https://vimeo.com/639305553" onClick={this.imageClick} alt="639305553"/></div>              
            
              <div className="maker_container"><img key="572293894" src={martin_nair} className="maker_image" video="https://vimeo.com/572293894" onClick={this.imageClick} alt="572293894"/></div>
              <div className="maker_container"><img key="572291060" src={martin_depend} className="maker_image" video="https://vimeo.com/572291060" onClick={this.imageClick} alt="572291060"/></div>
              <div className="maker_container"><img key="572294150" src={martin_adler} className="maker_image" video="https://vimeo.com/572294150" onClick={this.imageClick} alt="572294150"/></div>
              <div className="maker_container"><img key="572294341" src={martin_milkaut} className="maker_image" video="https://vimeo.com/572294341" onClick={this.imageClick} alt="572294341"/></div>
              <div className="maker_container"><img key="572295315" src={martin_vaquita} className="maker_image" video="https://vimeo.com/572295315" onClick={this.imageClick} alt="572295315"/></div>
              <div className="maker_container"><img key="572293964" src={martin_luuna} className="maker_image" video="https://vimeo.com/572293964" onClick={this.imageClick} alt="572293964"/></div>

              <div className="maker_container"><img key="541698393" src={martin_whiskas} className="maker_image" video="https://vimeo.com/541698393" onClick={this.imageClick} alt="541698393"/></div>

              <div className="maker_container"><img key="500134665" src={martin_rafael} className="maker_image" video="https://vimeo.com/500134665" onClick={this.imageClick} alt="500134665"/></div>
              <div className="maker_container"><img key="500150351" src={martin_fifa} className="maker_image" video="https://vimeo.com/500150351" onClick={this.imageClick} alt="500150351"/></div>
              <div className="maker_container"><img key="500141959" src={martin_santander} className="maker_image" video="https://vimeo.com/500141959" onClick={this.imageClick} alt="500141959"/></div>
              <div className="maker_container"><img key="500138632" src={martin_botika} className="maker_image" video="https://vimeo.com/500138632" onClick={this.imageClick} alt="500138632"/></div>
              
              <div className="maker_container"><img key="395008269" src={martin_reel} className="maker_image" video="https://vimeo.com/395008269" onClick={this.imageClick} alt="395008269"/></div>
              <div className="maker_container"><img key="395008606" src={martin_jump2} className="maker_image" video="https://vimeo.com/395008606" onClick={this.imageClick} alt="395008606"/></div>
              <div className="maker_container"><img key="395007914" src={martin_jump} className="maker_image" video="https://vimeo.com/395007914" onClick={this.imageClick} alt="395007914"/></div>
              <div className="maker_container"><img key="395007091" src={martin_adidas} className="maker_image" video="https://vimeo.com/395007091" onClick={this.imageClick} alt="395007091"/></div>
              <div className="maker_container"><img key="395008496" src={martin_seriepatia} className="maker_image" video="https://vimeo.com/395008496" onClick={this.imageClick} alt="395008496"/></div>
              <div className="maker_container"><img key="395008659" src={martin_beetle} className="maker_image" video="https://vimeo.com/395008659" onClick={this.imageClick} alt="395008659"/></div>
              <div className="maker_container"><img key="395008090" src={martin_mb} className="maker_image" video="https://vimeo.com/395008090" onClick={this.imageClick} alt="395008090"/></div>
              <div className="maker_container"><img key="395008172" src={martin_mercadopago} className="maker_image" video="https://vimeo.com/395008172" onClick={this.imageClick} alt="395008172"/></div>
              <div className="maker_container"><img key="395008250" src={martin_quaker} className="maker_image" video="https://vimeo.com/395008250" onClick={this.imageClick} alt="395008250"/></div>
              <div className="maker_container"><img key="395007159" src={martin_carola} className="maker_image" video="https://vimeo.com/395007159" onClick={this.imageClick} alt="395007159"/></div>
              <div className="maker_container"><img key="395007290" src={martin_cheetos} className="maker_image" video="https://vimeo.com/395007290" onClick={this.imageClick} alt="395007290"/></div>
              <div className="maker_container"><img key="395008540" src={martin_speedy} className="maker_image" video="https://vimeo.com/395008540" onClick={this.imageClick} alt="395008540"/></div>
              <div className="maker_container"><img key="395007896" src={martin_edenor} className="maker_image" video="https://vimeo.com/395007896" onClick={this.imageClick} alt="395007896"/></div>
              <div className="maker_container"><img key="395008201" src={martin_metrovias} className="maker_image" video="https://vimeo.com/395008201" onClick={this.imageClick} alt="395008201"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;