import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import comunicacion from "../../images/makers/comunicacion.png"
import diablos_rojos from "../../images/makers/diablos_rojos.png"
import hotel_awards from "../../images/makers/hotel_awards.png"
import lechera_mix from "../../images/makers/lechera_mix.png"
import lechera_pizza from "../../images/makers/lechera_pizza.png"
import ruta_cinepolis from "../../images/makers/ruta_cinepolis.png"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">Los Homies</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="639326427" src={comunicacion} className="maker_image" video="https://vimeo.com/639326427" onClick={this.imageClick} alt="639326427"/></div>
              <div className="maker_container"><img key="639326680" src={diablos_rojos} className="maker_image" video="https://vimeo.com/639326680" onClick={this.imageClick} alt="639326680"/></div>
              <div className="maker_container"><img key="639326836" src={hotel_awards} className="maker_image" video="https://vimeo.com/639326836" onClick={this.imageClick} alt="639326836"/></div>
              <div className="maker_container"><img key="639327092" src={lechera_mix} className="maker_image" video="https://vimeo.com/639327092" onClick={this.imageClick} alt="639327092"/></div>
              <div className="maker_container"><img key="639327195" src={lechera_pizza} className="maker_image" video="https://vimeo.com/639327195" onClick={this.imageClick} alt="639327195"/></div>
              <div className="maker_container"><img key="639328983" src={ruta_cinepolis} className="maker_image" video="https://vimeo.com/639328983" onClick={this.imageClick} alt="639328983"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;