import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import image1 from "../../images/makers/agustin1.png"
import image2 from "../../images/makers/agustin2.png"
import image3 from "../../images/makers/agustin3.png"
import image4 from "../../images/makers/agustin4.png"
import image5 from "../../images/makers/agustin5.png"
import image6 from "../../images/makers/agustin6.png"
import image7 from "../../images/makers/agustin7.png"
import image8 from "../../images/makers/agustin8.png"
import image9 from "../../images/makers/agustin9.png"
import image10 from "../../images/makers/agustin10.png"
import image11 from "../../images/makers/agustin11.png"
import image12 from "../../images/makers/agustin12.png"
import image13 from "../../images/makers/agustin13.png"
import image14 from "../../images/makers/agustin14.png"
import image15 from "../../images/makers/agustin15.png"
import image16 from "../../images/makers/agustin16.png"
import image17 from "../../images/makers/agustin17.png"
import image18 from "../../images/makers/agustin18.png"
import image19 from "../../images/makers/agustin19.png"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);

    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">Agustín<br/>Calderón</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="440847461" src={image1} className="maker_image" video="https://vimeo.com/440847461" onClick={this.imageClick} alt="440847461"/></div>
              <div className="maker_container"><img key="440846131" src={image2} className="maker_image" video="https://vimeo.com/440846131" onClick={this.imageClick} alt="440846131"/></div>
              <div className="maker_container"><img key="440810595" src={image3} className="maker_image" video="https://vimeo.com/440810595" onClick={this.imageClick} alt="440810595"/></div>
              <div className="maker_container"><img key="440800197" src={image4} className="maker_image" video="https://vimeo.com/440800197" onClick={this.imageClick} alt="440800197"/></div>
              <div className="maker_container"><img key="440757713" src={image5} className="maker_image" video="https://vimeo.com/440757713" onClick={this.imageClick} alt="440757713"/></div>
              <div className="maker_container"><img key="440743755" src={image6} className="maker_image" video="https://vimeo.com/440743755" onClick={this.imageClick} alt="440743755"/></div>
              <div className="maker_container"><img key="440736519" src={image7} className="maker_image" video="https://vimeo.com/440736519" onClick={this.imageClick} alt="440736519"/></div>
              <div className="maker_container"><img key="440729632" src={image8} className="maker_image" video="https://vimeo.com/440729632" onClick={this.imageClick} alt="440729632"/></div>
              <div className="maker_container"><img key="440721135" src={image9} className="maker_image" video="https://vimeo.com/440721135" onClick={this.imageClick} alt="440721135"/></div>
              <div className="maker_container"><img key="440716702" src={image10} className="maker_image" video="https://vimeo.com/440716702" onClick={this.imageClick} alt="440716702"/></div>
              <div className="maker_container"><img key="267309989" src={image11} className="maker_image" video="https://vimeo.com/267309989" onClick={this.imageClick} alt="267309989"/></div>
              <div className="maker_container"><img key="267307507" src={image12} className="maker_image" video="https://vimeo.com/267307507" onClick={this.imageClick} alt="267307507"/></div>
              <div className="maker_container"><img key="265256518" src={image13} className="maker_image" video="https://vimeo.com/265256518" onClick={this.imageClick} alt="265256518"/></div>
              <div className="maker_container"><img key="128530388" src={image14} className="maker_image" video="https://vimeo.com/128530388" onClick={this.imageClick} alt="128530388"/></div>
              <div className="maker_container"><img key="128530390" src={image15} className="maker_image" video="https://vimeo.com/128530390" onClick={this.imageClick} alt="128530390"/></div>
              <div className="maker_container"><img key="128530393" src={image16} className="maker_image" video="https://vimeo.com/128530393" onClick={this.imageClick} alt="128530393"/></div>
              <div className="maker_container"><img key="128696924" src={image17} className="maker_image" video="https://vimeo.com/128696924" onClick={this.imageClick} alt="128696924"/></div>
              <div className="maker_container"><img key="183717363" src={image18} className="maker_image" video="https://vimeo.com/183717363" onClick={this.imageClick} alt="183717363"/></div>
              <div className="maker_container"><img key="259775633" src={image19} className="maker_image" video="https://vimeo.com/259775633" onClick={this.imageClick} alt="259775633"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;