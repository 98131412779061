import React from 'react';
import { Link } from "react-router-dom";

class Card extends React.Component {

	constructor(props) {
    super(props);

    this.getReel = this.getReel.bind(this);
	}

	getSubtitle() {

		if (this.props.subtitle !== undefined) {
			return (
				<h4 className="card_subtitle">{this.props.subtitle}</h4>
			)
		} else {
			return null
		}

	}

	getReel() {

		if (this.props.reel !== undefined) {

			let link = this.props.reel + "/"
			return (
				<Link className="card_reel" to={link}>Click here >></Link>
			)
		} else {
			return null
		}

	}

	render () {

		let cardClass = this.props.odd === "true" ? "card odd" : "card"

		return (

			<div className={cardClass}>

				<div className="card_info">
					<h3 className="card_title">{this.props.title}</h3>
					{this.getSubtitle()}
					<p className="card_paragraph">{this.props.paragraph}</p>
					{this.getReel()}
				</div>

				<div className="card_frame">
					<img className="card_image" src={this.props.image} alt="card_image" />
				</div>

			</div>

		);
	}
}

export default Card;
