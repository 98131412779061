import React from 'react';

class Video extends React.Component {

	constructor(props) {
    super(props);
    this.menuClick = this.menuClick.bind(this);
	}

	menuClick(event) {
		event.preventDefault();
		document.getElementById('video').classList.toggle("hidden");
		document.getElementById('iframe').setAttribute("src", "")
	}

	render () {

		let video_url = "https://player.vimeo.com/video/" + this.props.video

		return (

			<div className="video hidden" id="video">


				<div className="video_container">
					<div className="video_close"><i className="video_icon fas fa-times" onClick={this.menuClick}></i></div>
					<iframe id="iframe" src={video_url} title="video" frameBorder="0" width="640" height="360" allow="autoplay; fullscreen" allowFullScreen></iframe>
				</div>

			</div>

		);
	}
}

export default Video;
