import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import toledo1 from "../../images/makers/toledo_1.png"
import toledo2 from "../../images/makers/toledo_2.png"
import toledo3 from "../../images/makers/toledo_3.png"
import toledo4 from "../../images/makers/toledo_4.png"
import toledo5 from "../../images/makers/toledo_5.png"
import toledo6 from "../../images/makers/toledo_6.png"
import toledo7 from "../../images/makers/toledo_7.png"
import toledo8 from "../../images/makers/toledo_8.png"
import toledo9 from "../../images/makers/toledo_9.png"
import toledo10 from "../../images/makers/toledo_10.png"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">Toledo & Zavala</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="518839068" src={toledo1} className="maker_image" video="https://vimeo.com/518839068" onClick={this.imageClick} alt="518839068"/></div>
              <div className="maker_container"><img key="518844480" src={toledo2} className="maker_image" video="https://vimeo.com/518844480" onClick={this.imageClick} alt="518844480"/></div>
              <div className="maker_container"><img key="518851299" src={toledo3} className="maker_image" video="https://vimeo.com/518851299" onClick={this.imageClick} alt="518851299"/></div>
              <div className="maker_container"><img key="518855539" src={toledo4} className="maker_image" video="https://vimeo.com/518855539" onClick={this.imageClick} alt="518855539"/></div>
              <div className="maker_container"><img key="518860346" src={toledo5} className="maker_image" video="https://vimeo.com/518860346" onClick={this.imageClick} alt="518860346"/></div>
              <div className="maker_container"><img key="518870432" src={toledo6} className="maker_image" video="https://vimeo.com/518870432" onClick={this.imageClick} alt="518870432"/></div>
              <div className="maker_container"><img key="518871270" src={toledo7} className="maker_image" video="https://vimeo.com/518871270" onClick={this.imageClick} alt="518871270"/></div>
              <div className="maker_container"><img key="451350262" src={toledo8} className="maker_image" video="https://vimeo.com/451350262" onClick={this.imageClick} alt="451350262"/></div>
              <div className="maker_container"><img key="300851955" src={toledo9} className="maker_image" video="https://vimeo.com/300851955" onClick={this.imageClick} alt="300851955"/></div>
              <div className="maker_container"><img key="518871424" src={toledo10} className="maker_image" video="https://vimeo.com/518871424" onClick={this.imageClick} alt="518871424"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;