import React from 'react';
// import { Link } from "react-router-dom";

import Footer from '../components/Footer';
import Header from '../components/Header';

// import Dermaheal from '../images/contents/Dermaheal.jpg';
// import Gflac from '../images/contents/Gflac.jpg';
// import Hedding from '../images/contents/Hedding.jpg';
// import Leyendas from '../images/contents/Leyendas.jpg';
// import LocuraAmor from '../images/contents/LocuraAmor.jpg';
// import SistemaPTX from '../images/contents/SistemaPTX.jpg';
// import Social from '../images/contents/Social.jpg';
// import Soriana from '../images/contents/Soriana.jpg';
// import SuperFoods from '../images/contents/SuperFoods.jpg';
// import VidaFitness from '../images/contents/VidaFitness.jpg';
// import VivaBonito from '../images/contents/VivaBonito.png';

import image from '../images/Contents.JPG';

class Contents extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();

    // const tag = document.query
    console.log(event.target.getAttribute('video'))
  }

  render () {

    return (

      <React.Fragment>

        <div className="main contents">

          <Header />

          <div className="content">

            <h2 className="title">Contents</h2>

            <p className="contents_p">Storytelling is one of the most powerful tools to get closer to audiences that is no longer exposed to mass media such as TV.  Through it we tell stories of interest to various sectors where a brand can be introduced in a “silent” way.  When used in marketing, the branded content, can expand much more and show the benefits of the product in full use of it, as well as expose actual themes, providing relevant information to new targets eager for knowledge.</p>
            
            <p className="contents_title">Our team of creative producers can develop many types of contents such as:</p>

            <ul className="contents_list">

              <li className="contents_item"><p className="list_paragrah">Tv spots</p></li>
              <li className="contents_item"><p className="list_paragrah">Tv series</p></li>
              <li className="contents_item"><p className="list_paragrah">Web series</p></li>
              <li className="contents_item"><p className="list_paragrah">Movies</p></li>
              <li className="contents_item"><p className="list_paragrah">Tutorials</p></li>
              <li className="contents_item"><p className="list_paragrah">Corporate videos</p></li>
              <li className="contents_item"><p className="list_paragrah">Podcasts</p></li>
              <li className="contents_item"><p className="list_paragrah">Packages of contents for your social media platforms</p></li>
              <li className="contents_item"><p className="list_paragrah">Explanatory animations</p></li>
              <li className="contents_item"><p className="list_paragrah">Radio spots & capsules</p></li>
              <li className="contents_item"><p className="list_paragrah">Expo stands videos</p></li>
              <li className="contents_item"><p className="list_paragrah">Music video clips</p></li>
              <li className="contents_item"><p className="list_paragrah">Experimental videos</p></li>
              <li className="contents_item"><p className="list_paragrah">Timelapse videos</p></li>
              <li className="contents_item"><p className="list_paragrah">Drone captures</p></li>
              <li className="contents_item"><p className="list_paragrah">Contents for restaurant displays, concerts, shop windows, and more</p></li>
              <li className="contents_item"><p className="list_paragrah">360-degree digital world for metaverse contents </p></li>

            </ul>

            <img className="contents_image" src={image} alt="Contents" />
            {/*<div className="contents_wrapper">
              <Link className="contents_link" to="vivabonito/"><img src={VivaBonito} className="contents_image" alt="VivaBonito" /></Link>
              <Link className="contents_link" to="dermaheal/"><img src={Dermaheal} className="contents_image" alt="Dermaheal" /></Link>
              <Link className="contents_link" to="soriana/"><img src={Soriana} className="contents_image" alt="Soriana" /></Link>
              <Link className="contents_link" to="gflac/"><img src={Gflac} className="contents_image" alt="Gflac" /></Link>
              <Link className="contents_link" to="hedding/"><img src={Hedding} className="contents_image" alt="Hedding" /></Link>
              <Link className="contents_link" to="vidafitness/"><img src={VidaFitness} className="contents_image" alt="VidaFitness" /></Link>
              <Link className="contents_link" to="/contents/"><img src={LocuraAmor} className="contents_image" alt="LocuraAmor" /></Link> 
              <Link className="contents_link" to="/contents/"><img src={Social} className="contents_image" alt="Social" /></Link> 
              <Link className="contents_link" to="/contents/"><img src={Leyendas} className="contents_image" alt="Leyendas" /></Link> 
              <Link className="contents_link" to="/contents/"><img src={SuperFoods} className="contents_image" alt="SuperFoods" /></Link> 
              <Link className="contents_link" to="ptx/"><img src={SistemaPTX} className="contents_image" alt="SistemaPTX" /></Link>
            </div>*/}

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Contents;