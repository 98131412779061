import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import mariog1 from "../../images/makers/mariog1.jpg"
import mariog2 from "../../images/makers/mariog2.jpg"
import mariog3 from "../../images/makers/mariog3.jpg"
import mariog4 from "../../images/makers/mariog4.jpg"
import mariog5 from "../../images/makers/mariog5.jpg"
import mariog6 from "../../images/makers/mariog6.jpg"
import mariog7 from "../../images/makers/mariog7.jpg"
import mariog8 from "../../images/makers/mariog8.jpg"
import mariog9 from "../../images/makers/mariog9.jpg"
import mariog10 from "../../images/makers/mariog10.jpg"
import mariog11 from "../../images/makers/mariog11.jpg"
import mariog12 from "../../images/makers/mariog12.jpg"
import mariog13 from "../../images/makers/mariog13.jpg"
import mariog14 from "../../images/makers/mariog14.jpg"
import mariog15 from "../../images/makers/mariog15.jpg"
import mariog16 from "../../images/makers/mariog16.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">Mario<br/>Gomez</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="361178547" src={mariog1} className="maker_image" video="https://vimeo.com/361178547" onClick={this.imageClick} alt="361178547"/></div>
              <div className="maker_container"><img key="361180047" src={mariog2} className="maker_image" video="https://vimeo.com/361180047" onClick={this.imageClick} alt="361180047"/></div>
              <div className="maker_container"><img key="361181898" src={mariog3} className="maker_image" video="https://vimeo.com/361181898" onClick={this.imageClick} alt="361181898"/></div>
              <div className="maker_container"><img key="361182775" src={mariog4} className="maker_image" video="https://vimeo.com/361182775" onClick={this.imageClick} alt="361182775"/></div>
              <div className="maker_container"><img key="361183592" src={mariog5} className="maker_image" video="https://vimeo.com/361183592" onClick={this.imageClick} alt="361183592"/></div>
              <div className="maker_container"><img key="361183724" src={mariog6} className="maker_image" video="https://vimeo.com/361183724" onClick={this.imageClick} alt="361183724"/></div>
              <div className="maker_container"><img key="361183767" src={mariog7} className="maker_image" video="https://vimeo.com/361183767" onClick={this.imageClick} alt="361183767"/></div>
              <div className="maker_container"><img key="361184090" src={mariog8} className="maker_image" video="https://vimeo.com/361184090" onClick={this.imageClick} alt="361184090"/></div>
              <div className="maker_container"><img key="361184182" src={mariog9} className="maker_image" video="https://vimeo.com/361184182" onClick={this.imageClick} alt="361184182"/></div>
              <div className="maker_container"><img key="361184439" src={mariog10} className="maker_image" video="https://vimeo.com/361184439" onClick={this.imageClick} alt="361184439"/></div>
              <div className="maker_container"><img key="361184573" src={mariog11} className="maker_image" video="https://vimeo.com/361184573" onClick={this.imageClick} alt="361184573"/></div>
              <div className="maker_container"><img key="361184703" src={mariog12} className="maker_image" video="https://vimeo.com/361184703" onClick={this.imageClick} alt="361184703"/></div>
              <div className="maker_container"><img key="361185057" src={mariog13} className="maker_image" video="https://vimeo.com/361185057" onClick={this.imageClick} alt="361185057"/></div>
              <div className="maker_container"><img key="361185128" src={mariog14} className="maker_image" video="https://vimeo.com/361185128" onClick={this.imageClick} alt="361185128"/></div>
              <div className="maker_container"><img key="361185172" src={mariog15} className="maker_image" video="https://vimeo.com/361185172" onClick={this.imageClick} alt="361185172"/></div>
              <div className="maker_container"><img key="361185255" src={mariog16} className="maker_image" video="https://vimeo.com/361185255" onClick={this.imageClick} alt="361185255"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;