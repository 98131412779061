import React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';

import face from '../images/face.png';
import flor from '../images/flor_abeja.png';

import eye from '../images/eye.png';
import hover from '../images/eye_hover.png';

import client1 from '../images/clients/client1.jpg';
import client2 from '../images/clients/client2.jpg';
import client3 from '../images/clients/client3.jpg';
import client4 from '../images/clients/client4.jpg';
import client5 from '../images/clients/client5.jpg';
import client6 from '../images/clients/client6.jpg';
import client7 from '../images/clients/client7.jpg';
import client8 from '../images/clients/client8.jpg';
import client9 from '../images/clients/client9.jpg';
import client10 from '../images/clients/client10.jpg';
import client11 from '../images/clients/client11.jpg';
import client12 from '../images/clients/client12.jpg';
import client13 from '../images/clients/client13.jpg';
import client14 from '../images/clients/client14.jpg';
import client15 from '../images/clients/client15.jpg';
import client16 from '../images/clients/client16.jpg';

import APlogo from '../images/logos/APlogo.jpeg'
// import AQUAPEEL2 from '../images/logos/AQUAPEEL2.png'
import AQULIFT from '../images/logos/AQULIFT.png'
import AquaPeel from '../images/logos/AquaPeel.png'
import AquaShine from '../images/logos/AquaShine.png'
import BOTIKA from '../images/logos/BOTIKA.png'
import BlowBlow from '../images/logos/BlowBlow.png'
import CCCream from '../images/logos/CCCream.jpeg'
import Caras from '../images/logos/Caras.jpg'
import Cosmeceutical from '../images/logos/Cosmeceutical.jpeg'
import Dermacosme from '../images/logos/Dermacosme.png'
import Dermaheal from '../images/logos/Dermaheal.png'
import DrCyj from '../images/logos/DrCyj.png'
import ELLE from '../images/logos/ELLE.jpg'
import ESTO from '../images/logos/ESTO.jpg'
import Excelsior from '../images/logos/Excelsior.jpg'
import EyeFillerSerum from '../images/logos/EyeFillerSerum.jpeg'
import FOLCRESS from '../images/logos/FOLCRESS.png'
import Forbes from '../images/logos/Forbes.jpg'
import GRISI from '../images/logos/GRISI.png'
import HONEYDerma from '../images/logos/HONEYDerma.png'
import Hydralips from '../images/logos/Hydralips.JPG'
import Hydropointer from '../images/logos/Hydropointer.png'
import ITPharma2 from '../images/logos/ITPharma2.png'
// import ItPharma from '../images/logos/ItPharma.png'
import LMAS from '../images/logos/LMAS.png'
import LaLista from '../images/logos/LaLista.jpg'
import LashLash from '../images/logos/LashLash.png'
import NaturalFill from '../images/logos/NaturalFill.png'
import Nestle from '../images/logos/Nestle.png'
import OEM from '../images/logos/OEM.jpg'
import PIMPIMPAUL_logo_sq from '../images/logos/PIMPIMPAUL_logo_sq.jpg'
import PURILUX from '../images/logos/PURILUX.png'
import Ptx from '../images/logos/Ptx.jpg'
import Publimetro from '../images/logos/Publimetro.jpg'
import QUIEN from '../images/logos/QUIEN.jpg'
import Reforma from '../images/logos/Reforma.jpg'
import Revofil from '../images/logos/Revofil.png'
import RevofilFine from '../images/logos/RevofilFine.png'
import SANRAFAEL from '../images/logos/SANRAFAEL.png'
import SinEmbargo from '../images/logos/SinEmbargo.jpg'
import TVyNovelas from '../images/logos/TVyNovelas.jpg'
import Televisa from '../images/logos/Televisa.jpg'
import Tiktok from '../images/logos/Tiktok.png'
import Tu from '../images/logos/Tu.jpg'
import WRadio from '../images/logos/WRadio.jpg'
import dermaexpert from '../images/logos/dermaexpert.jpg'
import vitalizing from '../images/logos/vitalizing.jpg'

import MapContainer from '../services/GoogleMaps'

class Home extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      iteration: true,
      delay: 1500,
    }

    this.bgInterval = this.bgInterval.bind(this);
    this.menuClick = this.menuClick.bind(this);
  }

  menuClick(event) {
    event.preventDefault();
    document.getElementById('navigation').classList.toggle("visible");
    document.getElementById('menu_button').classList.toggle("fa-bars");
    document.getElementById('menu_button').classList.toggle("fa-times");
  }

  bgInterval() {

    var iteration = this.state.iteration
    let slider = document.getElementById('slider_list')

    if (iteration) {
      
      slider.classList.remove("animation");
      slider.classList.add("animation");

    } else {

      var firstChild = slider.firstChild;
      slider.removeChild(firstChild)
      slider.appendChild(firstChild)

      slider.classList.remove("animation");

    }

    iteration = !iteration
    this.setState({iteration})

  }

  componentDidMount() {

    let section = window.location.pathname.replace("/", "");
    section = section.replace("/", "");

    if (section === null || section === "") { section = "home" }
    
    let item = document.getElementById(section)
    if (item != null) {
      item.classList.add("current");
    }

    var intervalId = setInterval(this.bgInterval, this.state.delay/2);
    this.setState({intervalId: intervalId});

  }

  componentWillUnmount() {
     clearInterval(this.state.intervalId);
  }

  render () {

    return (

      <React.Fragment>

        <div className="main home">

          <Header />

          <div className="content">

            <div className="banner">

              <div className="slogan">
                <p className="slogan_word">MAKE</p>
                <p className="slogan_word">YOUR</p>
                <p className="slogan_word">IDEAS</p>
                <p className="slogan_word">TURN</p>
                <p className="slogan_word">INTO</p>
                <p className="slogan_word">GOLD</p>
              </div>

              <div className="face_wrapper">
                <img src={face} className="face" alt="face" />
              </div>

            </div>

            <div className="banner us">

              <div className="we_are">
                <h3 className="h3">WE ARE</h3>
                <p className="p">
                  AN AUDIOVISUAL ADVERTISING &<br/>
                  CONTENT PRODUCTION COMPANY<br/>
                  BASED IN MEXICO CITY<br/>
                  SINCE 2006
                </p>
                <p className="p label">
                We offer video solutions connecting brands and people through cinematic storytelling.
                </p>
              </div>

              <div className="face_wrapper flor_wrapper">
                <img src={flor} className="face flor" alt="flor" />
              </div>

            </div>

            <div className="slider">

              <h3 className="h3">OUR CLIENTS</h3>

              <div className="slider_list" id="slider_list">
                  <div className="slider_wrapper"><img className="slider_image" src={client1} alt="client1" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client2} alt="client2" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client3} alt="client3" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client4} alt="client4" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client5} alt="client5" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client6} alt="client6" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client7} alt="client7" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client8} alt="client8" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client9} alt="client9" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client10} alt="client10" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client11} alt="client11" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client12} alt="client12" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client13} alt="client13" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client14} alt="client14" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client15} alt="client15" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={client16} alt="client16" /></div>
                  {/*<div className="slider_wrapper"><img className="slider_image" src={AQUAPEEL2} alt="AQUAPEEL2" /></div>*/}
                  {/*<div className="slider_wrapper"><img className="slider_image" src={ItPharma} alt="ItPharma" /></div>*/}

                  <div className="slider_wrapper"><img className="slider_image" src={APlogo} alt="APlogo" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={AQULIFT} alt="AQULIFT" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={AquaPeel} alt="AquaPeel" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={AquaShine} alt="AquaShine" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={BOTIKA} alt="BOTIKA" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={BlowBlow} alt="BlowBlow" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={CCCream} alt="CCCream" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Caras} alt="Caras" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Cosmeceutical} alt="Cosmeceutical" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Dermacosme} alt="Dermacosme" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Dermaheal} alt="Dermaheal" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={DrCyj} alt="DrCyj" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={ELLE} alt="ELLE" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={ESTO} alt="ESTO" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Excelsior} alt="Excelsior" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={EyeFillerSerum} alt="EyeFillerSerum" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={FOLCRESS} alt="FOLCRESS" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Forbes} alt="Forbes" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={GRISI} alt="GRISI" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={HONEYDerma} alt="HONEYDerma" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Hydralips} alt="Hydralips" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Hydropointer} alt="Hydropointer" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={ITPharma2} alt="ITPharma2" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={LMAS} alt="LMAS" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={LaLista} alt="LaLista" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={LashLash} alt="LashLash" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={NaturalFill} alt="NaturalFill" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Nestle} alt="Nestlè" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={OEM} alt="OEM" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={PIMPIMPAUL_logo_sq} alt="PIMPIMPAUL_logo_sq" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={PURILUX} alt="PURILUX" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Ptx} alt="Ptx" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Publimetro} alt="Publimetro" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={QUIEN} alt="QUIÉN" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Reforma} alt="Reforma" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Revofil} alt="Revofil" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={RevofilFine} alt="RevofilFine" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={SANRAFAEL} alt="SANRAFAEL" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={SinEmbargo} alt="SinEmbargo" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={TVyNovelas} alt="TVyNovelas" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Televisa} alt="Televisa" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Tiktok} alt="Tiktok" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={Tu} alt="Tú" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={WRadio} alt="WRadio" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={dermaexpert} alt="dermaexpert" /></div>
                  <div className="slider_wrapper"><img className="slider_image" src={vitalizing} alt="vitalizing" /></div>

              </div>

            </div>

            <div className="contact">
              <img src={hover} className="contact_image eye_hover" alt="contact us" />
              <img src={eye} className="contact_image eye" alt="contact us" />
            </div>

            <div className="map">
              <MapContainer />
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Home;
