import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import rafa1 from "../../images/makers/rafa1.jpg"
import rafa2 from "../../images/makers/rafa2.jpg"
import rafa3 from "../../images/makers/rafa3.jpg"
import rafa4 from "../../images/makers/rafa4.jpg"
import rafa5 from "../../images/makers/rafa5.jpg"
import rafa6 from "../../images/makers/rafa6.jpg"
import rafa7 from "../../images/makers/rafa7.jpg"
import rafa8 from "../../images/makers/rafa8.jpg"
import rafa9 from "../../images/makers/rafa9.jpg"
import rafa10 from "../../images/makers/rafa10.jpg"
import rafa11 from "../../images/makers/rafa11.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">Rafa<br/>Lara</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="223729151" src={rafa1} className="maker_image" video="https://vimeo.com/223729151" onClick={this.imageClick} alt="223729151"/></div>
              <div className="maker_container"><img key="223728960" src={rafa2} className="maker_image" video="https://vimeo.com/223728960" onClick={this.imageClick} alt="223728960"/></div>
              <div className="maker_container"><img key="223728786" src={rafa3} className="maker_image" video="https://vimeo.com/223728786" onClick={this.imageClick} alt="223728786"/></div>
              <div className="maker_container"><img key="223728300" src={rafa4} className="maker_image" video="https://vimeo.com/223728300" onClick={this.imageClick} alt="223728300"/></div>
              <div className="maker_container"><img key="223728141" src={rafa5} className="maker_image" video="https://vimeo.com/223728141" onClick={this.imageClick} alt="223728141"/></div>
              <div className="maker_container"><img key="223727556" src={rafa6} className="maker_image" video="https://vimeo.com/223727556" onClick={this.imageClick} alt="223727556"/></div>
              <div className="maker_container"><img key="223727270" src={rafa7} className="maker_image" video="https://vimeo.com/223727270" onClick={this.imageClick} alt="223727270"/></div>
              <div className="maker_container"><img key="223726461" src={rafa8} className="maker_image" video="https://vimeo.com/223726461" onClick={this.imageClick} alt="223726461"/></div>
              <div className="maker_container"><img key="223725980" src={rafa9} className="maker_image" video="https://vimeo.com/223725980" onClick={this.imageClick} alt="223725980"/></div>
              <div className="maker_container"><img key="223726340" src={rafa10} className="maker_image" video="https://vimeo.com/223726340" onClick={this.imageClick} alt="223726340"/></div>
              <div className="maker_container"><img key="223725533" src={rafa11} className="maker_image" video="https://vimeo.com/223725533" onClick={this.imageClick} alt="223725533"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;