import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import dermaheal1 from "../../images/contents/dermaheal1.jpg"
import dermaheal2 from "../../images/contents/dermaheal2.jpg"
import dermaheal3 from "../../images/contents/dermaheal3.jpg"
import dermaheal4 from "../../images/contents/dermaheal4.jpg"
import dermaheal5 from "../../images/contents/dermaheal5.jpg"
import dermaheal6 from "../../images/contents/dermaheal6.jpg"
import dermaheal7 from "../../images/contents/dermaheal7.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">DERMAHEAL<br/>2019</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="361185638" src={dermaheal1} className="maker_image" video="https://vimeo.com/361185638" onClick={this.imageClick} alt="361185638"/></div>
              <div className="maker_container"><img key="361185987" src={dermaheal2} className="maker_image" video="https://vimeo.com/361185987" onClick={this.imageClick} alt="361185987"/></div>
              <div className="maker_container"><img key="361186292" src={dermaheal3} className="maker_image" video="https://vimeo.com/361186292" onClick={this.imageClick} alt="361186292"/></div>
              <div className="maker_container"><img key="361186614" src={dermaheal4} className="maker_image" video="https://vimeo.com/361186614" onClick={this.imageClick} alt="361186614"/></div>
              <div className="maker_container"><img key="361187087" src={dermaheal5} className="maker_image" video="https://vimeo.com/361187087" onClick={this.imageClick} alt="361187087"/></div>
              <div className="maker_container"><img key="361187528" src={dermaheal6} className="maker_image" video="https://vimeo.com/361187528" onClick={this.imageClick} alt="361187528"/></div>
              <div className="maker_container"><img key="361188897" src={dermaheal7} className="maker_image" video="https://vimeo.com/361188897" onClick={this.imageClick} alt="361188897"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;