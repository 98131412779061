import React, { Component } from 'react';
import { Link } from "react-router-dom";
import logo from '../images/logo.png';

class Header extends Component {

  constructor (props) {
    super(props);
    this.menuClick = this.menuClick.bind(this);
  }

	menuClick(event) {
		event.preventDefault();
		document.getElementById('navigation').classList.toggle("visible");
		// document.getElementById('body').classList.toggle("fixed");
		document.getElementById('menu_button').classList.toggle("fa-bars");
		document.getElementById('menu_button').classList.toggle("fa-times");
	}

	componentDidMount() {
		let section = window.location.pathname.replace("/", "");
		section = section.replace("/", "");

		// Comment this line to remove bottom line in "Home" item in header.
		if (section === null || section === "") { section = "home" }
		
		let item = document.getElementById(section)
		if (item != null) {
			item.classList.add("current");
		}

	}

	render () {

		return (

			<header className="header">

				<div className="header_content">

					<div className="header_logo_wrapper">
						<Link className="nav_link" id="home" to="/"><img src={logo} className="header_logo" alt="header_logo" /></Link>
					</div>
					
					<nav className="nav_container" id="navigation">
						<ul className="nav_list">
							<li className="nav_item"><Link className="nav_link" id="home" to="/">Home</Link></li>
							<li className="nav_item"><Link className="nav_link" id="filmmakers" to="/filmmakers/">The Filmmakers</Link></li>
							<li className="nav_item"><Link className="nav_link" id="producer" to="/producer/">LaProducer</Link></li>
							<li className="nav_item"><Link className="nav_link" id="planner" to="/planner/">The Producers</Link></li>
							<li className="nav_item"><Link className="nav_link" id="services" to="/services/">Production Services</Link></li>
							<li className="nav_item"><Link className="nav_link" id="contents" to="/contents/">Contents</Link></li>
						</ul>
					</nav>

					<div className="menu_button_container" id="menu_button_container">
						<i className="fas fa-bars menu_button" id="menu_button" onClick={this.menuClick} ></i>
					</div>

				</div>

			</header>

		);

	}
	
}

export default Header;
