import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import movil from "../../images/makers/movil.png"
import uaca from "../../images/makers/uaca.png"

import balboa from "../../images/makers/balboa.png"
import bliss from "../../images/makers/bliss.png"
import bice_pool from "../../images/makers/bice_pool.png"
import bice_rusia from "../../images/makers/bice_rusia.png"
import bnp from "../../images/makers/bnp.png"
import cinehouse from "../../images/makers/cinehouse.png"
import copaes from "../../images/makers/copaes.png"
import daihatsu from "../../images/makers/daihatsu.png"
import digicel from "../../images/makers/digicel.png"
import fidelitas from "../../images/makers/fidelitas.png"
import fidelitas_ing from "../../images/makers/fidelitas_ing.png"
import glade_chick from "../../images/makers/glade_chick.png"
import glade_orchard from "../../images/makers/glade_orchard.png"
import imperial from "../../images/makers/imperial.png"
import link from "../../images/makers/link.png"
import maxx from "../../images/makers/maxx.png"
import movistar from "../../images/makers/movistar.png"
import omo from "../../images/makers/omo.png"
import oval_globo from "../../images/makers/oval_globo.png"
import oval_trampolin from "../../images/makers/oval_trampolin.png"
import panaviera from "../../images/makers/panaviera.png"
import ponds from "../../images/makers/ponds.png"
import scotiabank from "../../images/makers/scotiabank.png"
import sky from "../../images/makers/sky.png"
import sony from "../../images/makers/sony.png"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">Leo Ganora</h2>

            <div className="maker_wrapper">
              
              <div className="maker_container"><img key="719888934" src={movil} className="maker_image" video="https://vimeo.com/719888934" onClick={this.imageClick} alt="719888934"/></div>
              <div className="maker_container"><img key="719889802" src={uaca} className="maker_image" video="https://vimeo.com/719889802" onClick={this.imageClick} alt="719889802"/></div>
              
              <div className="maker_container"><img key="639245060" src={balboa} className="maker_image" video="https://vimeo.com/639245060" onClick={this.imageClick} alt="639245060"/></div>
              <div className="maker_container"><img key="639256393" src={bliss} className="maker_image" video="https://vimeo.com/639256393" onClick={this.imageClick} alt="639256393"/></div>
              <div className="maker_container"><img key="639248373" src={bice_pool} className="maker_image" video="https://vimeo.com/639248373" onClick={this.imageClick} alt="639248373"/></div>
              <div className="maker_container"><img key="639251742" src={bice_rusia} className="maker_image" video="https://vimeo.com/639251742" onClick={this.imageClick} alt="639251742"/></div>
              <div className="maker_container"><img key="639264600" src={bnp} className="maker_image" video="https://vimeo.com/639264600" onClick={this.imageClick} alt="639264600"/></div>
              <div className="maker_container"><img key="639268826" src={cinehouse} className="maker_image" video="https://vimeo.com/639268826" onClick={this.imageClick} alt="639268826"/></div>
              <div className="maker_container"><img key="639302238" src={copaes} className="maker_image" video="https://vimeo.com/639302238" onClick={this.imageClick} alt="639302238"/></div>
              <div className="maker_container"><img key="639311228" src={daihatsu} className="maker_image" video="https://vimeo.com/639311228" onClick={this.imageClick} alt="639311228"/></div>
              <div className="maker_container"><img key="639312846" src={digicel} className="maker_image" video="https://vimeo.com/639312846" onClick={this.imageClick} alt="639312846"/></div>
              <div className="maker_container"><img key="639313620" src={fidelitas} className="maker_image" video="https://vimeo.com/639313620" onClick={this.imageClick} alt="639313620"/></div>
              <div className="maker_container"><img key="639313823" src={fidelitas_ing} className="maker_image" video="https://vimeo.com/639313823" onClick={this.imageClick} alt="639313823"/></div>
              <div className="maker_container"><img key="639314182" src={glade_chick} className="maker_image" video="https://vimeo.com/639314182" onClick={this.imageClick} alt="639314182"/></div>
              <div className="maker_container"><img key="639314717" src={glade_orchard} className="maker_image" video="https://vimeo.com/639314717" onClick={this.imageClick} alt="639314717"/></div>
              <div className="maker_container"><img key="639314844" src={imperial} className="maker_image" video="https://vimeo.com/639314844" onClick={this.imageClick} alt="639314844"/></div>
              <div className="maker_container"><img key="639315412" src={link} className="maker_image" video="https://vimeo.com/639315412" onClick={this.imageClick} alt="639315412"/></div>
              <div className="maker_container"><img key="639315680" src={maxx} className="maker_image" video="https://vimeo.com/639315680" onClick={this.imageClick} alt="639315680"/></div>
              <div className="maker_container"><img key="639315785" src={movistar} className="maker_image" video="https://vimeo.com/639315785" onClick={this.imageClick} alt="639315785"/></div>
              <div className="maker_container"><img key="639315980" src={omo} className="maker_image" video="https://vimeo.com/639315980" onClick={this.imageClick} alt="639315980"/></div>
              <div className="maker_container"><img key="639316184" src={oval_globo} className="maker_image" video="https://vimeo.com/639316184" onClick={this.imageClick} alt="639316184"/></div>
              <div className="maker_container"><img key="639316318" src={oval_trampolin} className="maker_image" video="https://vimeo.com/639316318" onClick={this.imageClick} alt="639316318"/></div>
              <div className="maker_container"><img key="639316401" src={panaviera} className="maker_image" video="https://vimeo.com/639316401" onClick={this.imageClick} alt="639316401"/></div>
              <div className="maker_container"><img key="639316575" src={ponds} className="maker_image" video="https://vimeo.com/639316575" onClick={this.imageClick} alt="639316575"/></div>
              <div className="maker_container"><img key="639316773" src={scotiabank} className="maker_image" video="https://vimeo.com/639316773" onClick={this.imageClick} alt="639316773"/></div>
              <div className="maker_container"><img key="639316907" src={sky} className="maker_image" video="https://vimeo.com/639316907" onClick={this.imageClick} alt="639316907"/></div>
              <div className="maker_container"><img key="639317123" src={sony} className="maker_image" video="https://vimeo.com/639317123" onClick={this.imageClick} alt="639317123"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;