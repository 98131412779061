
import React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';

import mexico from '../images/mexico.jpg';
import footer from '../images/footer.png';

class Services extends React.Component {

  render () {

    return (

      <React.Fragment>

        <div className="main services">

          <Header />

          <div className="content">

            <h2 className="title">Production<br/>Services</h2>

            <div className="services_title_wrapper">
              <p className="services_title">Mexico is well known for its international award winners at the cinematography industry.  <br />We are creative producers of all kinds of contents.</p>
              {/*<p className="services_title bold">THANKS MR. TRUMP!</p>*/}
            </div>

            <img className="services_image" src={mexico} alt="mexico" />
            <p className="services_p">Throughout our history at the production company, we have built a full-service team, with highly specialized crews in film production for any type of projects that satisfies the most exacting standards of our clients.</p>
            <p className="services_p">At TheRep MX, you can find bilingual people for any query.  From amazing Hollywood movies to tv spots, tv series, digital media, art movies, documentaries, and many other contents.</p>
            <p className="services_p">Mexico is an amazing destination for shooting also.  We have all kinds of climates and locations with permission management, multicultural casting, amazing DoPs, art directors, make up and costume designers, prosthetics, foreign team accommodation, transportation, time lapse, drones, state of the art equipment, etc., and everything in affordable rates.</p>
            <p className="services_p">We are part of a Concierge VIP experience for clients and celebrities if desired: glam teams, chefs, masseuses, chauffers with luxury vehicles, personal security service, touristic guides, and many other services to create great experiences full of glamor.</p>
            {/*<p className="services_p">Please write us at: services@therep.mx</p>*/}

            <div className="services_offers">

              <h4 className="offers_title">We have many things to offer such as:</h4>

              <div class="offers_wrapper">

                <ul class="offers_list">
                  <li class="offers_item">amazing locations<br/>(beaches, deserts, villages, cities)</li>
                  <li class="offers_item">multicultural casting</li>
                  <li class="offers_item">best producers</li>
                  <li class="offers_item">amazing DoP's</li>
                  <li class="offers_item">art directors</li>
                  <li class="offers_item">logistics</li>
                  <li class="offers_item">crew accommodation</li>
                  <li class="offers_item">transportation</li>
                  <li class="offers_item">VIP customer service</li>
                  <li class="offers_item">concierge & drivers 24 hrs</li>
                </ul>

                <div class="offers_info">
                  <p class="offers_p">And the best part, combine your job with lots of fun, tourism and shopping.</p>
                  <p class="offers_p">* If you need a budget, don't hesitate to write us: <a href="mailto:services@therep.mx">services@therep.mx</a></p>
                </div>

              </div>

            </div>

            <img src={footer} class="footer_img" alt="footer_img" />

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Services;