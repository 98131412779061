import React from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Video from '../../components/Video';

import hills from "../../images/makers/hills.png"
import capsiflu from "../../images/makers/capsiflu.png"
// import unefon from "../../images/makers/unefon.png"
import steelers from "../../images/makers/steelers.png"

import mario8 from "../../images/makers/mario8.jpg"
import mario9 from "../../images/makers/mario9.jpg"
import mario10 from "../../images/makers/mario10.jpg"
import mario11 from "../../images/makers/mario11.jpg"
import mario12 from "../../images/makers/mario12.jpg"
import mario13 from "../../images/makers/mario13.jpg"
import mario14 from "../../images/makers/mario14.jpg"
import mario7 from "../../images/makers/mario7.jpg"
import mario1 from "../../images/makers/mario1.jpg"
import mario2 from "../../images/makers/mario2.jpg"
import mario3 from "../../images/makers/mario3.jpg"
import mario4 from "../../images/makers/mario4.jpg"
import mario5 from "../../images/makers/mario5.jpg"
import mario6 from "../../images/makers/mario6.jpg"

class Filmmakers extends React.Component {

  constructor (props) {
    super(props);
    this.imageClick = this.imageClick.bind(this);
  }

  imageClick(event) {
    event.preventDefault();
    let url = "https://player.vimeo.com/video/" + event.target.getAttribute('alt')
    document.getElementById('iframe').setAttribute("src", url)
    document.getElementById('video').classList.toggle("hidden")
  }

  render () {

    return (

      <React.Fragment>

        <Video id="video" />

        <div className="main maker">

          <Header />

          <div className="content">

            <h2 className="title">Mario<br/>Zavala</h2>

            <div className="maker_wrapper">
              <div className="maker_container"><img key="712968750" src={hills} className="maker_image" video="https://vimeo.com/712968750" onClick={this.imageClick} alt="712968750"/></div>
              <div className="maker_container"><img key="712971890" src={capsiflu} className="maker_image" video="https://vimeo.com/712971890" onClick={this.imageClick} alt="712971890"/></div>
              {/*<div className="maker_container"><img key="712971890" src={unefon} className="maker_image" video="https://vimeo.com/712971890" onClick={this.imageClick} alt="712971890"/></div>*/}
              <div className="maker_container"><img key="712978117" src={steelers} className="maker_image" video="https://vimeo.com/712978117" onClick={this.imageClick} alt="712978117"/></div>
              
              <div className="maker_container"><img key="361168018" src={mario8} className="maker_image" video="https://vimeo.com/361168018" onClick={this.imageClick} alt="361168018"/></div>
              <div className="maker_container"><img key="361169386" src={mario9} className="maker_image" video="https://vimeo.com/361169386" onClick={this.imageClick} alt="361169386"/></div>
              <div className="maker_container"><img key="361117827" src={mario10} className="maker_image" video="https://vimeo.com/361117827" onClick={this.imageClick} alt="361117827"/></div>
              <div className="maker_container"><img key="361166465" src={mario11} className="maker_image" video="https://vimeo.com/361166465" onClick={this.imageClick} alt="361166465"/></div>
              <div className="maker_container"><img key="361137512" src={mario12} className="maker_image" video="https://vimeo.com/361137512" onClick={this.imageClick} alt="361137512"/></div>
              <div className="maker_container"><img key="361120543" src={mario13} className="maker_image" video="https://vimeo.com/361120543" onClick={this.imageClick} alt="361120543"/></div>
              <div className="maker_container"><img key="361127715" src={mario14} className="maker_image" video="https://vimeo.com/361127715" onClick={this.imageClick} alt="361127715"/></div>
              <div className="maker_container"><img key="329228212" src={mario7} className="maker_image" video="https://vimeo.com/329228212" onClick={this.imageClick} alt="329228212"/></div>
              <div className="maker_container"><img key="282576680" src={mario1} className="maker_image" video="https://vimeo.com/282576680" onClick={this.imageClick} alt="282576680"/></div>
              <div className="maker_container"><img key="282577321" src={mario2} className="maker_image" video="https://vimeo.com/282577321" onClick={this.imageClick} alt="282577321"/></div>
              <div className="maker_container"><img key="297421543" src={mario3} className="maker_image" video="https://vimeo.com/297421543" onClick={this.imageClick} alt="297421543"/></div>
              <div className="maker_container"><img key="297420241" src={mario4} className="maker_image" video="https://vimeo.com/297420241" onClick={this.imageClick} alt="297420241"/></div>
              <div className="maker_container"><img key="297421396" src={mario5} className="maker_image" video="https://vimeo.com/297421396" onClick={this.imageClick} alt="297421396"/></div>
              <div className="maker_container"><img key="300851955" src={mario6} className="maker_image" video="https://vimeo.com/300851955" onClick={this.imageClick} alt="300851955"/></div>
            </div>

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Filmmakers;