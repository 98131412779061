import React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';

// import Card from '../components/Card';

import producers from '../images/filmmakers/TheProducers.JPG';

class Planner extends React.Component {

  render () {

    return (

      <React.Fragment>

        <div className="main planner">

          <Header />

          <div className="content">

            <h2 className="title">The<br/>Producers</h2>

            <div className="services_title_wrapper">
              <p className="services_title">The executive producers oversee carrying out each project to ensure that the creative and editorial goals are met.  They are in charge of taking the briefs, budgeting, pre-producing, producing and post-producing the materials to deliver the best quality to our clients.  There is always someone available to happily suite your needs.</p>
              {/*<p className="services_title bold">THANKS MR. TRUMP!</p>*/}
            </div>

            <img className="services_image" src={producers} alt="mexico" />

            {/*<Card 
              image={planner}
              title="Abraham Pavón"
              subtitle="MEDIA PLANNER & COMMUNITY MANAGER"
              paragraph="The executive producers oversee carrying out each project to ensure that the creative and editorial goals are met.  They are in charge of taking the briefs, budgeting, pre-producing, producing and post-producing the materials to deliver the best quality to our clients.  There is always someone available to happily suite your needs." 
              odd="true"
            />*/}

          </div>

          <Footer />

        </div>

      </React.Fragment>

    );
  }
}

export default Planner;